<template>
  <div class="news-page">
    <template v-if="paginationNews">
      <div class="news container-fluid">
        <div class="news__header">
          <div class="container">
            <div class="news-header__title">
              <span>{{ $t('news_page.how') }}</span>
            </div>
            <div class="news-header__input">
              <img src="./img/search-img.svg" alt="search" />
              <input type="search" v-model="filters.search" />
            </div>
          </div>
        </div>
        <div class="news__body">
          <div class="container">
            <div class="news-body__tabs">
              <!-- @click="handleToggleTag(tag)" -->
              <!-- <div
                v-for="news in listNews"
                :key="news.id"
                class="listing item"
              >
                <span>{{ news }}</span>
              </div> -->
            </div>
            <!-- :class="{ 'active-tag': filters.tag }" -->
            <div class="news-body__list">
              <div class="row">
                <div
                  class="col-md-6 col-sm-6 listing item"
                  v-for="news in newsList"
                  :key="news._id"
                >
                  <div class="item-box" v-if="news">
                    <div class="img">
                      <img :src="news.imgUrl" :alt="news.title" />
                    </div>
                    <div class="title">
                      <span>{{ news.title | intl }}</span>
                    </div>
                    <div class="list-tools">
                      <div class="item">
                        <div class="icon">
                          <img src="./img/arrowRight.svg" alt="arrowRight" />
                        </div>
                        <div class="text">
                          <span
                            style="word-break: break-word;max-width: 100%;"
                            >{{ news.preview | intl }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="read-more">
                      <router-link
                        :to="{ name: 'newsItem', params: { id: news._id } }"
                      >
                        <button>Read more</button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-2 empty-block"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- pagination block  -->
        <div class="news__pagination">
          <div class="pagination-block">
            <div
              class="arrow-left-box"
              v-if="paginationNews.hasPrevPage"
              @click="toPage(currentPageView - 1)"
            >
              <svg
                width="14"
                height="25"
                viewBox="0 0 14 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.635519 10.9027L10.9552 0.583399C11.6116 -0.0733738 12.6759 -0.0733738 13.3321 0.583399C13.9883 1.23958 13.9883 2.30386 13.3321 2.95999L4.20079 12.091L13.3318 21.2217C13.988 21.8781 13.988 22.9423 13.3318 23.5985C12.6756 24.2549 11.6113 24.2549 10.9549 23.5985L0.635254 13.279C0.307159 12.9508 0.143298 12.521 0.143298 12.091C0.143298 11.6609 0.307479 11.2308 0.635519 10.9027Z"
                  fill="#A9C7EF"
                />
              </svg>
            </div>

            <div class="pages-box">
              <div
                class="item"
                v-for="i in totalPages"
                :key="i"
                :class="{ active: currentPageView == i }"
                @click="toPage(i)"
              >
                {{ i }}
              </div>
            </div>
            <div
              class="arrow-right-box"
              v-if="paginationNews.hasNextPage"
              @click="toPage(currentPageView + 1)"
            >
              <svg
                class="right"
                width="14"
                height="25"
                viewBox="0 0 14 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.635519 10.9027L10.9552 0.583399C11.6116 -0.0733738 12.6759 -0.0733738 13.3321 0.583399C13.9883 1.23958 13.9883 2.30386 13.3321 2.95999L4.20079 12.091L13.3318 21.2217C13.988 21.8781 13.988 22.9423 13.3318 23.5985C12.6756 24.2549 11.6113 24.2549 10.9549 23.5985L0.635254 13.279C0.307159 12.9508 0.143298 12.521 0.143298 12.091C0.143298 11.6609 0.307479 11.2308 0.635519 10.9027Z"
                  fill="#A9C7EF"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="cards container-fluid">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-4 cards_accept">
              <div class="cards__title">
                <span>{{ $t('news_page.accept') }}</span>
              </div>
            </div>
            <div class="col-md-8 col-sm-8 cards__list">
              <div class="skrill">
                <img src="~@assets/img/skrill.png" alt="skrill" />
              </div>
              <div class="visa">
                <img src="~@assets/img/visa.png" alt="visa" />
              </div>
              <div class="mc">
                <img src="~@assets/img/mc.png" alt="master_card" />
              </div>
              <div class="neteller">
                <img src="~@assets/img/neteller.png" alt="neteller" />
              </div>
              <div class="wire">
                <img src="~@assets/img/wire.png" alt="wire" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <UiPreloader />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

function chunks(arr, size = 2) {
  return arr
    .map((x, i) => i % size == 0 && arr.slice(i, i + size))
    .filter(x => x)
}

export default {
  data: () => ({
    filters: {
      search: '',
      tag: undefined,
    },

    newsList: [],
    paginationNews: undefined,

    limitView: 6,
    currentPageView: 1,
  }),

  computed: {
    tags() {
      // console.log(this.getterNewsByTags)
      return Object.keys(this.getterNewsByTags)
    },

    totalPages() {
      return this.paginationNews?.totalPages || 1
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('News', ['getNews']),

    handleToggleTag(tag) {
      this.filters.tag = this.filters.tag == tag ? undefined : tag
    },

    getData() {
      this.getNews({
        limit: this.limitView,
        page: this.currentPageView,
        sort: { createdAt: -1 },
      }).then(data => {
        const { docs, ...pagination } = data
        this.paginationNews = pagination
        this.newsList = docs
      })
    },

    toPage(page) {
      this.currentPageView = page
      this.getData()
      setTimeout(() => {
        window.scroll(0, 0)
      }, 200)
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
